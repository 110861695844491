<template>
    <Layout>
        <div>
            <h1 class="text-h4 mb-8">
                Twoje konto, <span class="font-weight-bold">{{ $store.getters['auth/userFirstName'] }}</span>
            </h1>

            <v-row>
                <v-col cols="12" md="6">
                    <v-sheet
                        outlined
                        rounded
                        class="pa-4"
                    >
                        <h4 class="text-h6 mt-0 mb-4 d-flex align-center">
                            <div>
                                Dane osobowe
                            </div>

                            <v-spacer></v-spacer>
                            <v-btn
                                icon
                                color="grey"
                                small
                                title="Edytuj swoje dane"
                                @click.stop="_ => {
                                    edit_dialog = true;
                                    edit_dialog_data = JSON.parse(JSON.stringify(user));    
                                }"
                            >
                                <v-icon size="20">mdi-pencil</v-icon>
                            </v-btn>
                        </h4>

                        <v-list-item dense class="px-0">
                            <v-list-item-content>
                                Imię i nazwisko
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                                <div class="font-weight-medium">
                                    {{ user.name }}
                                </div>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense class="px-0">
                            <v-list-item-content>
                                <div>
                                    Stanowisko
                                    <!-- <v-tooltip right max-width="350">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon 
                                                size="12" 
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                mdi-help-circle
                                            </v-icon>
                                        </template>
                                        <span>
                                            Wyświetlane upadającemu m.in. w oknie czatu, ma za zadanie zwiększać zaufanie. 
                                        </span>
                                    </v-tooltip> -->
                                </div>
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                                <div class="font-weight-medium">
                                    {{ user.position || '-' }}
                                </div>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense class="px-0">
                            <v-list-item-content>
                                Adres e-mail
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                                <div class="font-weight-medium">
                                    {{ user.email }}
                                </div>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense class="px-0">
                            <v-list-item-content>
                                Zdjęcie profilowe
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                                <div> 
                                    <v-avatar
                                        :size="64"
                                        v-if="user.profile_image && user.profile_image != ''"
                                        class="mb-2"
                                    >
                                        <v-img :src="user.profile_image"></v-img>
                                    </v-avatar>
                                    <v-icon 
                                        :size="48" 
                                        color="grey"
                                        v-else
                                    >mdi-image-off</v-icon>

                                    <!-- <v-btn
                                        text
                                        color="grey"
                                        small
                                        class="ml-2"
                                        @click="_ => {
                                            $refs.profile_image_input.click();
                                        }"
                                        :loading="uploading_avatar"
                                    >
                                        Zmień zdjęcie
                                        <v-tooltip right max-width="350">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon 
                                                    size="12" 
                                                    class="ml-1"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    mdi-help-circle
                                                </v-icon>
                                            </template>
                                            <span>
                                                Plik JPG lub PNG o rozmiarze nieprzekraczającym 4 MB. Minimalna rozdzielczość pliku: 1024 px. 
                                            </span>
                                        </v-tooltip>
                                    </v-btn> -->
                                    <input 
                                        type="file" 
                                        ref="profile_image_input" 
                                        style="position: fixed; top: 100%; left: 10000px; width: 0; height: 0;"
                                        @change="handleFilePick"
                                    />
                                    <ImageCropper 
                                        :show="avatar_crop_dialog"
                                        :image="avatar_file_base64"

                                        @cancel="onAvatarCropCancel"
                                        @crop="onAvatarCrop"
                                    />
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-sheet>

                    <!-- ACC EDIT DIALOG -->
                    <v-dialog
                        v-model="edit_dialog"
                        scrollable
                        max-width="500px"
                        persistent
                    >
                        <v-card>
                            <v-card-title>Edytuj dane konta</v-card-title>
                            <v-divider></v-divider>
                            <v-card-text style="max-height: 80vh;" class="py-8">
                                <v-text-field 
                                    outlined
                                    label="Adres e-mail"
                                    disabled
                                    v-model="edit_dialog_data.email"
                                    type="email"
                                    name="email"
                                />

                                <v-text-field 
                                    outlined
                                    label="Imię i nazwisko"
                                    v-model="edit_dialog_data.name"
                                    ref="edit_dialog_name"
                                    :rules="[
                                        v => {
                                            if(!v || v.length == 0){
                                                return 'To pole jest wymagane';
                                            }
                                            if(v.length > 31){
                                                return 'Maksymalna długość pola to 31 znaków';
                                            }
                                            return true;
                                        }
                                    ]"
                                    type="text"
                                    name="name"
                                    @keydown.enter="onAccEdit"
                                />

                                <v-text-field 
                                    outlined
                                    label="Stanowisko"
                                    v-model="edit_dialog_data.position"
                                    ref="edit_dialog_position"
                                    :rules="[
                                        v => {
                                            if(v.length > 31){
                                                return 'Maksymalna długość pola to 31 znaków';
                                            }
                                            return true;
                                        }
                                    ]"
                                    type="text"
                                    name="position"
                                    @keydown.enter="onAccEdit"
                                />

                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-btn
                                    color="grey"
                                    text
                                    @click="edit_dialog = false;"
                                >
                                    Anuluj
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="success"
                                    text
                                    :loading="edit_dialog_loading"
                                    @click="onAccEdit"
                                >
                                    Zapisz zmiany
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
                <v-col cols="12" md="6">
                    <v-sheet
                        outlined
                        rounded
                        class="pa-4"
                    >
                        <h4 class="text-h6 mt-0 mb-4">
                            Pozostałe informacje
                        </h4>

                        <v-list-item dense class="px-0">
                            <v-list-item-content>
                                Poziom uprawnień
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                                <div>
                                    <v-chip
                                        small
                                        :color="$store.state.USER_ROLE_COLORS[user.role]"
                                    >
                                        <div class="white--text">
                                            {{ $store.state.USER_ROLE_LABELS[user.role] }}
                                        </div>
                                    </v-chip>
                                </div>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense class="px-0">
                            <v-list-item-content>
                                Status
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                                <div>
                                    <v-chip
                                        small
                                        :color="$store.state.USER_STATUSES_COLORS[user.status]"
                                    >
                                        {{ $store.state.USER_STATUSES_LABELS[user.status] }}
                                    </v-chip>
                                </div>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense class="px-0">
                            <v-list-item-content>
                                Data utworzenia konta
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                                <div class="font-weight-medium">
                                    {{ (new Date(user.c_date * 1000)).toLocaleString() }}
                                </div>
                            </v-list-item-content>
                        </v-list-item>                        

                    </v-sheet>
                </v-col>
                <v-col cols="12" md="6">
                    <v-sheet
                        outlined
                        rounded
                        class="pa-4"
                    >
                        <h4 class="text-h6 mt-0 mb-6">
                            Zmiana hasła
                        </h4>

                        <v-text-field 
                            outlined
                            label="Obecne hasło"
                            v-model="pwd_reset_old"
                            :rules="[
                                v => {
                                    if(!v || v.length == 0) return 'To pole jest wymagane';
                                    if(v.length < 8) return 'Minimalna długość pola: 8 znaków';
                                    if(v.length > 63) return 'Maksymalna długość pola: 63 znaki';
                                    if(!$store.getters['PASSWORD_VALIDATOR'](v)){
                                        return 'Hasło musi zawierać przynajmniej 1 małą literę, 1 wielką literę, 1 cyfrę oraz 1 znak specjalny';
                                    }
                                    return true;
                                }
                            ]"
                            ref="pwd_reset_old"
                            type="password"
                            name="current_password"
                            @keydown.enter="onPwdChange"
                        />

                        <v-text-field 
                            outlined
                            label="Nowe hasło"
                            v-model="pwd_reset_new"
                            :rules="[
                                v => {
                                    if(!v || v.length == 0) return 'To pole jest wymagane';
                                    if(v.length < 8) return 'Minimalna długość pola: 8 znaków';
                                    if(v.length > 63) return 'Maksymalna długość pola: 63 znaki';
                                    if(!$store.getters['PASSWORD_VALIDATOR'](v)){
                                        return 'Hasło musi zawierać przynajmniej 1 małą literę, 1 wielką literę, 1 cyfrę oraz 1 znak specjalny';
                                    }
                                    return true;
                                }
                            ]"
                            ref="pwd_reset_new"
                            type="password"
                            name="new_password"
                            @keydown.enter="onPwdChange"
                        />

                        <v-text-field 
                            outlined
                            label="Powtórz nowe hasło"
                            v-model="pwd_reset_new_repeat"
                            :rules="[
                                v => {
                                    if(!v || v == ''){
                                        return 'To pole jest wymagane';
                                    }
                                    if(v !== pwd_reset_new){
                                        return 'Podane hasła różnią się od siebie';
                                    }
                                    return true;
                                }
                            ]"
                            ref="pwd_reset_new_repeat"
                            type="password"
                            name="new_password_repeat"
                            @keydown.enter="onPwdChange"
                        />

                        <v-btn
                            color="secondary"
                            :loading="pwd_reset_loading"
                            @click="onPwdChange"
                        >
                            Zmień hasło
                        </v-btn>
                    </v-sheet>
                </v-col>
                <v-col cols="12" md="6">
                    <v-sheet
                        outlined
                        rounded
                        class="pa-4"
                    >
                        <h4 class="text-h6 mt-0 mb-4">
                            Wyloguj ze wszystkich urządzeń
                        </h4>

                        <div class="grey--text text-body-2">
                            Kliknij poniżej, aby zakończyć wszystkie rozpoczęte sesje. Skorzystaj z tej funkcji <span class="font-weight-medium">każdorazowo</span> po zmianie hasła.
                        </div>

                        <v-btn
                            block
                            color="warning"
                            small
                            class="mt-2"
                            @click="_ => {
                                $store.dispatch('auth/logOut', {
                                    logout_from_all_devices: true
                                });
                            }"
                        >
                            Wyloguj ze wszystkich urządzeń
                        </v-btn>
                    </v-sheet>
                </v-col>
            </v-row>
        </div>
    </Layout>
</template>

<script>
import ImageCropper from '@/components/ImageCropper';
import inspectPhoto from '@/helpers/inspect-photo';

export default {

    components: {
        ImageCropper
    },

    data(){
        return {

            pwd_reset_old: '',
            pwd_reset_new: '',
            pwd_reset_new_repeat: '',
            pwd_reset_loading: false,

            uploading_avatar: false,
            avatar_file: null,
            avatar_file_reader: null,
            avatar_file_base64: null,

            avatar_crop_dialog: false,

            edit_dialog: false,
            edit_dialog_loading: false,
            edit_dialog_data: {}
        }
    },
    
    computed: {
        user(){
            return this.$store.state.auth.user || {};
        }
    },

    methods: {

        // pwd reset
        async onPwdChange(){
            if(this.pwd_reset_loading) return;

            const a = [
                this.$refs.pwd_reset_old.validate(true),
                this.$refs.pwd_reset_new.validate(true),
                this.$refs.pwd_reset_new_repeat.validate(true)
            ];
            if(a.indexOf(false) !== -1) return;

            this.pwd_reset_loading = true;

            try{
                
                await this.$axios.$put("/users/me/password", {
                    old_password: this.pwd_reset_old,
                    new_password: this.pwd_reset_new
                });

                this.$message({
                    type: 'success',
                    msg: 'Hasło do Twojego konta zostało zmienione'
                });

                this.pwd_reset_old = this.pwd_reset_new = this.pwd_reset_new_repeat = '';
                this.$refs.pwd_reset_old.resetValidation();
                this.$refs.pwd_reset_new.resetValidation();
                this.$refs.pwd_reset_new_repeat.resetValidation();

            }
            catch(err){ console.error(err); }

            this.pwd_reset_loading = false;
        },

        // aktualizacja danych konta
        async onAccEdit(){
            if(this.edit_dialog_loading) return;

            const a = [
                this.$refs.edit_dialog_name.validate(true),
                this.$refs.edit_dialog_position.validate(true)
            ];
            if(a.indexOf(false) !== -1) return;

            this.edit_dialog_loading = true;

            try{

                await this.$axios.$put("/users/me", {
                    name: this.edit_dialog_data.name,
                    position: this.edit_dialog_data.position
                });

                this.$message({
                    type: 'success',
                    msg: 'Dane zapisane'
                });

                this.$store.commit('auth/setUser', {
                    ...this.$store.getters['auth/getUser'],
                    name: this.edit_dialog_data.name,
                    position: this.edit_dialog_data.position
                });

                this.edit_dialog = false;

            }
            catch(err){ console.error(err); }

            this.edit_dialog_loading = false;
        },

        // zmiana profilówki
        async handleFilePick(ev){
            if(ev.srcElement.files.length !== 1) return;

            //walidacja pliku
            const file = ev.srcElement.files[0];
            if(
                file.type != 'image/jpeg'
                && file.type != 'image/png'
            ){
                this.$message({
                    type: 'error',
                    msg: "Dozwolone są tylko pliki JPG i PNG"
                });
                this.clearInput();
                return;
            }

            if(file.size > 4194304){
                this.$message({
                    type: 'error',
                    msg: "Maksymalny rozmiar pliku to 4MB"
                });
                this.clearInput();
                return;
            }

            // sprawdzenie MP pliku
            const maxMP = 20;
            const photoSpecs = await inspectPhoto(file);
            if(photoSpecs.megapixels > maxMP){
                this.$message({
                    type: 'error',
                    msg: "Obraz może zawierać maksymalnie 20 megapikseli"
                });
                this.clearInput();
                return;
            }

            if(photoSpecs.width < 1024 || photoSpecs.height < 1024){
                this.$message({
                    type: 'error',
                    msg: "Wgraj obraz o rozdzielczości przynajmniej 1024x1024 pikseli"
                });
                this.clearInput();
                return;
            }

            if(photoSpecs.width > 5000 || photoSpecs.height > 5000){
                this.$message({
                    type: 'error',
                    msg: "Maksymalna obsługiwana rozdzielczość obrazu to 5000x5000px"
                });
                this.clearInput();
                return;
            }

            this.avatar_file = file;
            this.avatar_file_reader = new FileReader();
            this.avatar_file_reader.onload = function(e){

                this.avatar_file_base64 = e.target.result;
                this.avatar_crop_dialog = true;

            }.bind(this);

            this.avatar_file_reader.readAsDataURL(this.avatar_file);
        },
        onAvatarCropCancel(){
            this.avatar_crop_dialog = false;
            this.avatar_file_base64 = null;
            this.avatar_file_reader = null;
            this.clearInput();
        },
        async onAvatarCrop(cropData){
            // console.log(cropData);

            // zaokrąglanie wartości - koordynaty matematycznie, rozmiar cropboxa w dół, żeby na pewno było 1:1
            cropData.x = Math.round(cropData.x);
            cropData.y = Math.round(cropData.y);
            cropData.width = Math.floor(cropData.width);
            cropData.height = Math.floor(cropData.height);

            // jeżeli cropbox jest mniejszy niż 256x256 to error bo to kaszana będzie
            if(cropData.width < 512){
                return this.$message({
                    type: 'error',
                    msg: `Wgraj większy obraz lub zwiększ obszar przycięcia, aby finalne zdjęcie miało rozdzielczość przynajmniej 512x512px (obecnie ${cropData.width}x${cropData.height}px)`,
                    duration: 5000
                });
            }

            // jak git to upload
            if(this.uploading_avatar) return;
            this.uploading_avatar = true;
            this.avatar_crop_dialog = false;

            try{

                const FD = new FormData();
                FD.append('image', this.avatar_file);
                FD.append('crop_x', cropData.x);
                FD.append('crop_y', cropData.y);
                FD.append('crop_height', cropData.height);
                FD.append('crop_width', cropData.width);

                const r = await this.$axios.$post(
                    `/users/me/profile-image`, 
                    FD,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );

                this.$store.commit(
                    'auth/setUser',
                    {
                        ...this.$store.getters['auth/getUser'],
                        profile_image: r.profile_image
                    }
                );

            }
            catch(err){ console.error(err) }

            this.clearInput();
            this.uploading_avatar = false;
        },
        clearInput(){
            this.$refs['profile_image_input'].value = "";
            this.avatar_file = null;
        },
        
    },

    metaInfo: {
        title: 'Moje konto'
    }
}
</script>