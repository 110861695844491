<template>
    <div class="pe-image-cropper">
        <v-dialog
            v-model="show"
            max-width="500"
            scrollable
            persistent
        >
            <v-card>
                <v-card-title class="text-h5">
                    Przytnij zdjęcie
                </v-card-title>

                <v-card-text style="max-height: 80vh;">
                    <div style="max-height: 450px">
                        <img ref="cropper_target" :src="image" alt="" style="display: block; width: 100%">
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="grey"
                        text
                        @click="$emit('cancel', true)"
                    >
                        Anuluj
                    </v-btn>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="onCrop"
                    >
                        Gotowe
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Cropper from 'cropperjs';

export default {
    
    props: {
        image: {
            type: String,
            default: ''
        },
        show: {
            type: Boolean,
            default: false
        },
        aspectRatio: {
            type: Number,
            default: 1
        },
        viewMode: {
            type: Number,
            default: 2
        },
        dragMode: {
            type: String,
            default: 'move'
        },
        movable: {
            type: Boolean,
            default: false
        },
        rotatable: {
            type: Boolean,
            default: false
        },
        scalable: {
            type: Boolean,
            default: false
        },
        zoomable: {
            type: Boolean,
            default: false
        },
        toggleDragModeOnDblclick: {
            type: Boolean,
            default: false
        },
        minCropBoxWidth: {
            type: Number,
            default: 100
        },
        minCropBoxHeight: {
            type: Number,
            default: 100
        }
    },

    data(){
        return {

            cropper: null
        }
    },

    watch: {
        show(){
            if(this.show == true){
                this.$nextTick(this.mountCropper);
            }
            else{
                this.destroyCropper();
            }
        }
    },

    methods: {
        onCrop(){
            this.$emit('crop', this.cropper.getData());
        },

        mountCropper(){
            this.cropper = new Cropper(
                this.$refs['cropper_target'],
                {
                    aspectRatio: this.aspectRatio,
                    viewMode: this.viewMode,
                    dragMode: this.dragMode,
                    movable: this.movable,
                    rotatable: this.rotatable,
                    scalable: this.scalable,
                    zoomable: this.zoomable,
                    toggleDragModeOnDblclick: this.toggleDragModeOnDblclick,
                    minCropBoxWidth: this.minCropBoxWidth,
                    minCropBoxHeight: this.minCropBoxHeight
                }
            )
        },
        destroyCropper(){
            if(this.cropper != null){
                this.cropper.destroy();
                this.cropper = null;
            }
        }
    }
}
</script>