'use strict';

export default function(file){
    return new Promise((resolve, reject) => {
        
        const IMG = new Image();
        IMG.onload = () => {
            // console.log(IMG.width, IMG.height);
            return resolve({
                width: IMG.width,
                height: IMG.height,
                megapixels: (IMG.width * IMG.height / 1000000)
            });
        }
        IMG.onerror = (err) => {
            return reject(err); // zwracamy za dużo żeby się wysypało
        }

        const FR = new FileReader();
        FR.onload = () => {
            IMG.src = FR.result;
        }
        FR.onerror = (err) => {
            return reject(err); // zwracamy za dużo żeby się wysypało
        }
        FR.readAsDataURL(file);

    });
}